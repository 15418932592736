import { SubmenuComponent } from './../../pagina/submenu/submenu.component';
import { MaterialModule } from './../../pagina/Utilitarios/material.module';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { DefaultComponent } from './default.component';
import { FooterComponent } from 'src/app/pagina/footer/footer.component';

@NgModule({
  declarations: [
    DefaultComponent,
    SubmenuComponent,
    FooterComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    MaterialModule,
  ],

  exports: [ DefaultComponent ]
})
export class DefaultModule {

}
