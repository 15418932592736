import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bm-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
})
export class SubmenuComponent implements OnInit {
  constructor(private router: Router) {}

  facebook: any;
  whatsApp: any;
  teste = window.onresize;
  ngOnInit(): void {

  }

  abrirFacebook(){
    if(window.innerWidth < 500){
      this.facebook = "https://m.facebook.com/bruzonmussiadv"
    } else {
      this.facebook = "https://www.facebook.com/bruzonmussiadv"
    }

    return this.facebook
  }

 /*  resolucaoAtual() {
    if (window.innerWidth < 500) {
      return false;
    } else {
      return true;
    }
  } */

  /* https://api.whatsapp.com/send?phone=seu-numero&text=mensagem-inicial */
  abrirContato() {
    this.whatsApp = "https://api.whatsapp.com/send?phone=5543991385573"
  }
  topTrabalheConosco(){
    if(window.innerWidth < 500){
      window.scroll(800,100)
    } else {
      window.scroll(800,0)
    }

    this.router.navigate(["/trabalheConosco"])
  }



  topSobreNos(){
    if(window.innerWidth < 500){
      window.scroll(800,200)
    } else{
      window.scrollTo(800,620)
    }
    this.router.navigate(["/"])
  }

  topAreaDeAtuacao(){
    if(window.innerWidth < 500){
      window.scroll(800,1350)
    }else{
      window.scrollTo(800,1350)
    }
    this.router.navigate(["/"])
  }

  topConteudo(){
    if(window.innerWidth < 500){
      window.scroll(800,2580)

    } else{
      window.scrollTo(800,1680)
    }
    this.router.navigate(["/"])
  }

  topHome(){
    if(window.innerWidth < 500){
      window.scroll(800,0)
    } else{
      window.scrollTo(800,0)
    }
    this.router.navigate(["/"])
  }


}
