<!-- <bm-header></bm-header> -->
  <bm-slide></bm-slide>
<!-- Container de valores sobre e valores da empresa  -->
<section class="containerSobreNos" id="containerSobreNos">
  <div class="containerSobre">
      <h1>SOBRE NÓS</h1>
      <hr class="hr" size="4px" width="50px" color="#fff">
      <p class="txt">
        A Bruzon Mussi Advocacia é um escritório focado na advocacia empresarial, atuando como parceiro estratégico de seus Clientes, com serviços realizados com excelência e por meio de métodos comprovados.

        Nossos serviços são customizados para cada Cliente a partir do entendimento de suas necessidades, para entregarem valor e contribuírem para um fluxo constante de melhoria dos resultados dos nossos Clientes.

        Investimos intensivamente em pessoas, tecnologias e processos, sempre alinhados às melhores práticas e conceitos de classe mundial.

      </p>

      <p class="txt">
        Entre em contato com a Bruzon Mussi Advocacia e conheça nossa oferta de valor.
      </p>

      <div class="botao">
      <a (click)="abrirContato()"><button class="faleConosco"><strong>FALE CONOSCO</strong></button></a>
      </div>
  </div>

  <div class="valores">
          <div class="containerComponente">
            <div class="componente">
              <i class="circle fas fa-user-tie"></i>
            </div>
          </div>
          <div  class="descricao">
            <h3>Propósito</h3>
            <p>Trabalhar de forma significativa para o sucesso dos negócios de nossos Clientes através da máxima segurança jurídica.</p>
          </div>

          <div class="containerComponente">
            <div class="componente">
              <i class=" circle fas fa-balance-scale"></i>
            </div>
          </div>
          <div  class="descricao">
            <h3>Por que existimos?</h3>
            <p>Contribuímos com nossa experiência e conhecimento para simplificar as relações de negócios promovendo a conciliação entre as partes, maximizar a segurança jurídica e os resultados de nossos Clientes.</p>
          </div>

          <div class="containerComponente">
            <div class="componente">
              <i class=" circle fas fa-user-shield"></i>
            </div>
          </div>
          <div  class="descricao">
            <h3>O que tem valor para nós?</h3>
            <p>Atuar com <strong>ética, responsabilidade</strong> e <strong>transparência</strong>, focando na <strong>excelência</strong> dos <strong>resultados</strong> , estando atentos à <strong>inovação</strong> e as oportunidades de <strong>melhoria contínua</strong>, proporcionando <strong>tranquilidade</strong> jurídica à sociedade.</p>
          </div>


          <div class="containerComponente">
            <div class="componente">
              <img class="icoImg" src="/assets/image/futuro.svg" alt="O que queremos para o futuro">
            </div>
          </div>
          <div  class="descricao">
            <h3>O que queremos para o futuro?</h3>
            <p>Sermos reconhecidos como a melhor opção full-service em advocacia empresarial.</p>
          </div>


          <div class="containerComponente">
            <div class="componente">
              <img class="icoImg" src="/assets/image/parceiro.svg" alt="Como fazemos isso">
            </div>
          </div>
          <div  class="descricao">
            <h3>Como fazemos isso?</h3>
            <p>Nos colocamos, lado a lado, como parceiros estratégicos durante a jornada empresarial dos nossos Clientes, ofertando serviços de advocacia personalizados a partir do profundo entendimento de suas reais necessidades.</p>
          </div>

          <div class="containerComponente">
            <div class="componente">
              <i class=" circle fas fa-book"></i>
            </div>
          </div>
          <div  class="descricao">
            <h3>O que oferecemos aos nossos clientes?</h3>
            <ul>
              <li>Equipe qualificada e atualizada</li>
              <li>Agilidade no atendimento</li>
              <li>Compromisso com o resultado</li>
              <div id="areaAtuacao"></div>
              <li>Transparência no acompanhamento das demandas</li>
              <li>Processos, ferramentas e tecnologias de excelência</li>
            </ul>
            <div id="areaAtuaçãoMobile"></div>
          </div>
  </div>
</section>
<!--  Container Area de Atuação -->
<section class="containerAreaDeAtuacao" >
        <div class="txtAreaDeAtuacao">
          <h1 class="cor">ÁREAS DE ATUAÇÃO</h1>
        </div>
          <hr class="hr" size="3px" width="60px" color="#000">
          <h5>Selecione a área de seu interesse para saber mais</h5>
        <div class="container">
          <a (click)="topoRecuperacao()">
            <div class="contrato">
                <img width="100px" height="100px" src="/assets/image/Logo Contrato.png" alt="">
                <div class="txtContratoDireito">
                  <h4>RECUPERAÇÃO DE CRÉDITO</h4>
                </div>
            </div>
          </a>

         <!--  <a (click)="topDigital()">
            <div  class="direito">
                <img width="100px" height="100px" src="/assets/image/Logo Direito Digital.png" alt="">
                <div class="txtContratoDireto">
                  <h4>DIREITO DIGITAL</h4>
                </div>
            </div>
          </a> -->



          <a (click)="topCivel()">
            <div  class="direito">
                <img width="80px" height="90px" src="/assets/image/file-contract-solid.png" alt="">
                <div class="txtContratoCivel">
                  <h4>CÍVEL E CONTRATOS</h4>
                </div >
                <div id="conteudo"></div>
            </div>
          </a>
        </div>
      </section>
      <!-- Sessão de CONTEUDO  -->
<section class="containerConteudo">
      <div class="txtAreaDeAtuacao">
        <h1 class="cor">CONTEÚDO</h1>
      </div>
      <hr class="hr" size="3px" width="50px" color="#000">
      <h4 *ngIf="listaConteudo.length== 0 && !spinnerVisivel == true ? true:false" style="margin-top: 20px;">Não há conteúdos disponíveis no monento, retorne mais tarde! </h4>
      <div class="carregaFoto" *ngIf="spinnerVisivel">
        <mat-spinner *ngIf="spinnerVisivel"></mat-spinner>
        <img class="iconCarregar"  src="/assets/image/aguardeCarregamento.png" *ngIf="spinnerVisivel" alt="icone" />
      </div>
      <div *ngIf="listaConteudo.length== 0  ? false: true" class="containerPrincipalConteudo1" style="overflow-y: hidden">
        <div *ngIf="!spinnerVisivel" [class]="modoMobile === true ? 'containerPrincipalConteudoModoMobile':'containerPrincipalConteudo'">
            <button [disabled]="tamanhoDaPagina === 0?true:false" (click)="buscarConteudoMobile('<')" *ngIf="modoMobile === true"><i [class]="tamanhoDaPagina === 0 ? 'desabilitaSeta fas fa-arrow-alt-circle-left' :'seta fas fa-arrow-alt-circle-left'"></i></button>
            <div *ngFor=" let conteudoFor of listaConteudo" class="div1">
                <img class="img" [src]="conteudoFor.image" alt="">
                <div class="conteudo">
                  <div class="formatDescricao" >
                    <strong style="margin-left: 5px;">{{conteudoFor.titulo}}</strong>
                    <i *ngIf="conteudoFor.tipoConteudo === 'Notícia' || conteudoFor.tipoConteudo === 'E-book'"
                      class=" icoTitulo fas fa-book-open"></i>
                    <i *ngIf="conteudoFor.tipoConteudo === 'Podcast'" class=" icoTitulo fas fa-music"></i>
                    <i *ngIf="conteudoFor.tipoConteudo === 'Vídeo'" class=" icoTitulo fas fa-play"></i>
                  </div>
                  <div style="font-size: 10px;"> <i class="fas fa-calendar-alt" style="margin-left: 10px;"></i> {{conteudoFor.data }}</div>
                    <div  class='txtConteudo' >
                      <a (click)="formulario(conteudoFor.id)" title="Clique e leia a materia completa">
                        <textarea resize="resize" readonly="readonly" [innerText]="conteudoFor.conteudo" title="texto">
                        </textarea>
                      </a>
                    </div>
                  </div>
            </div>
            <button [disabled]="tamanhoDaPagina === totalPages-1 ?true:false" (click)="buscarConteudoMobile('>')" *ngIf="modoMobile === true"><i [class]="tamanhoDaPagina ===  totalPages-1 ? 'desabilitaSeta fas fa-arrow-alt-circle-right' :'seta fas fa-arrow-alt-circle-right'"></i></button>
        </div>
        <div *ngIf="cookies ===  'false'? false:true" class="msgcookies">
          <div class="formatarCookies">
            <strong>Este site não faz uso de cookies</strong>
            <button (click)="clicKookies()" class="btncookies" type="button">OK</button>
          </div>
        </div>
      </div>

      <div class="btnVejaMais" *ngIf="listaConteudo.length== 0  ? false: true">
        <a (click)="formularioConteudo()"><button class="vejaMais"><strong>VEJA MAIS</strong></button></a>
      </div>
</section>
