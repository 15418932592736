<section class="section">
  <div class="containerPrimario">
    <div class="containerSecundario">
      <div class="bruzunMussi">
        <h2 class="txtH2">BRUZON MUSSI ADVOCACIA</h2>
        <div class="visiteNossoEscritorio">
          <i class="fas fa-map-marker-alt" style="color: #fff;"></i>
          <h6 class="colorH6"><strong>Visite nosso escritório</strong></h6>
        </div>
        <div id="localizacao">
          <h6 class="colorH6"><a href="https://bit.ly/3wXIakE" target="_blank"><p id="txtR" style=" margin-top: -10px;">Rua dos Patriotas, 515 – Centro CEP 86.900-000 – Jandaia do Sul – PR</p>
          </a>
          </h6>
          <h6 class="colorH6" ></h6>
        </div>
        <div class="endereco">
          <i class="fas fa-phone-alt  " style="color: #fff;"></i>
          <h6 class="colorH6"><strong>Entre em contato conosco</strong></h6>
        </div>
        <div class="email">
            <h6 class="colorH6"><i class="fas fa-phone-square "style="color: #fff; margin-right: 5px;"></i><a href="tel:433432-6822">(43) 3432-6822</a></h6>
            <h6 class="colorH6"><i class="fab fa-whatsapp" style="color: #fff; margin-right: 8px;"></i><a (click)="abrirContato()">(43) 9 9138-5573</a></h6>
            <a id="copiaEmail" (click)="copyForClipboard($event)"><i style="margin-right: 8px;" class="fas fa-paste"></i>contato@bruzonmussi.com.br</a>
            <!-- <h6 class="colorH6"><a (click)="copyForClipboard($event)" id="copiaEmail"><i style="margin-right: 8px;" class="fas fa-paste"></i>contato@bruzonmussi.com.br</a></h6> -->
        </div>
        <div class="teste">
          <div class="atendimento">
            <i class="fas fa-clock" style="color: #fff;"></i>
            <h6 class="colorH6"><strong><a (click)="abrirContato()">Agende seu atendimento presencial</a></strong></h6>
            <div id="atendimento">
              <h6 class="colorH6" ><p style="margin-left: 11px; margin-top: -5px;">De segunda-feira a sexta-feira</p>
                <p style="margin-left: 11px; margin-top: -3px;">Das 8h às 18h</p>
              </h6>
            </div>
          </div>
        </div>
      </div>
      <div class="areaAtuacao">
        <h2 class="txtH2">Áreas de atuação</h2>
        <hr noshade size="3px" width="50px" color="#fff">
        <div class="txtAreaAtuacao">
          <a (click)="topoRecuperacao()">
            <p>Recuperação de crédito</p>
          </a>
          <!--   <a (click)="topDigital()">
              <p>Direito digital</p>
            </a> -->
          <a (click)="topCivel()">
            <p>Cível e contratos</p>
          </a>
        </div>
      </div>
      <div  class="conteudo">
        <h2 class="txtH2">Conteúdos</h2>
        <hr class="hrConteudo" noshade size="3px" width="50px" color="#fff">
        <ul class="ulConteudo" *ngFor="let categoria of listaTipoConteudo" >
          <li (click)="routerParaConteudo(categoria.id); top()"><a>{{categoria.descricao}}</a></li>
        </ul>
      </div>
      <div class="sobreNos" id="sobreNos">
        <h2 class="txtH2" >Sobre nós</h2>
        <hr noshade size="3px" width="50px" color="#fff">
        <a>
          <p><a (click)="topPolitica();"> Política de privacidade</a></p>
        </a>
        <a>
          <p style="margin-top: -8px;"><a (click)="topTrabalheConosco()">Trabalhe conosco</a></p>
        </a>
      </div>
    </div>
  </div>
  <footer class="ff">
    <p class="footer">copyrigths&copy; All Rights Reserved By Bruzon Mussi Advocacia  </p>
  </footer>
</section>
