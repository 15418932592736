import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { BmErros } from '../Utilitarios/bm-erros';
import { Data } from '@angular/router';
import { environment } from 'src/environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpClient,
    private bmErros: BmErros
  ) { }

  pesquisar(servico: string, parametros: any): Observable<any> {
    const params = Object.assign({}, parametros);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        if (typeof element === 'string' && element === '') {
          params.nome = '';
        }
      }
    }
    return this.http.get(environment.BASE_URL + servico, { params: Object.assign(params),
      responseType: 'json'}).pipe(map((resposta: any) => {
      if (resposta) {
        return resposta;
      }
    }), catchError((resposta: HttpErrorResponse) => {
      this.bmErros.showErro(resposta.error.message);
      return  throwError(resposta);
    }));
  }

}
