
 <div class="formularioVisitante">
    <div  class="txtCentral">
      <div class="sair"  (click)="closeDialog()"><i class="fas fa-times"></i></div>
      <h6>
        Por favor, preencha o formulário para acessar o conteúdo.
      </h6>
    </div>
    <div>
      <form #form="ngForm" class="form" #formContent>
            <div class="form">
              <mat-form-field class="matFormField" >
                <mat-label>Email</mat-label>
                <input #mail cdkFocusInitial #valorEmail name="email" id="email" [ngModel]="email" (blur)="buscaDadosUsuario(valorEmail.value)" matInput [formControl]="emailFormControl" [errorStateMatcher]="matcher"
                      title="Email" >
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                </mat-error>
                <mat-error  *ngIf="emailFormControl.hasError('required')">
                E-mail é <strong>requerido</strong>
                </mat-error>
              </mat-form-field>
              <div class="form2">
                <mat-form-field>
                  <mat-label>Nome Completo</mat-label>
                      <input matInput name="nome" id="nome"  [ngModel]="nome" [required]="true" title="Nome Completo" >
                      <mat-error *ngIf="controlFormError.hasError('required')">
                        Nome é <strong>Obrigatório*</strong>
                      </mat-error>
                </mat-form-field>
                  <mat-form-field>
                    <mat-label>Telefone</mat-label>
                    <input matInput name="telefone" id="telefone"  [ngModel]="telefone" mask="(00) 00000 - 0000 " [required]="true" title="Telefone">
                    <mat-error *ngIf="controlFormError.hasError('required')">
                      Telefone é <strong>Obrigatório*</strong>
                      </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <mat-label>Empresa</mat-label>
                    <input matInput  name="empresa" id="empresa"  [ngModel]="empresa" [required]="true" title="Empresa">
                    <mat-error *ngIf="controlFormError.hasError('required')">
                      Empresa é <strong>Obrigatório*</strong>
                      </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                  <mat-label>Cargo</mat-label>
                    <input matInput  name="cargo" id="cargo" [ngModel]="cargo" [required]="true" title="Cargo">
                    <mat-error *ngIf="controlFormError.hasError('required')">
                      Cargo é <strong>Obrigatório*</strong>
                      </mat-error>
                  </mat-form-field>
                  <mat-form-field class="matFormField">
                    <mat-label>Mensagem</mat-label>
                    <textarea name="mensagem" id="menssagem" [ngModel]="mensagem" style="resize: none;" matInput placeholder="Deixe aqui sua menssagem..."></textarea>
                      <!--   <input matInput  name="mensagem" id="menssagem" [ngModel]="mensagem" [required]="true"> -->
                    <mat-error *ngIf="controlFormError.hasError('required')">
                      Menssagem é <strong>Obrigatório*
                      </strong>
                      </mat-error>
                  </mat-form-field>
              </div>
            </div>

          </form>
          <div class="formatarMenssagem" >
            <p>Ao clicar no botão abaixo, você confirma que leu e aceita nossa <a href="#politicaDePrivacidade" target="blanck">Política de Privacidade</a></p>
            <div class="botaoEnvio">
              <button [disabled]="emailFormControl.status === 'VALID' ? false:true && statusBotao " id="enviar"  type="submit"  mat-raised-button color="primary" (click)="salvar(form.value,valorEmail.value,form)">Salvar</button>
            </div>
          </div>
    </div>
 </div>
