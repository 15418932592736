import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

  logo = '../../../assets/Images/LOGO.png';

  constructor(private router: Router) { }

  menu: any[] = [
    {
      descricao: 'Comunicados',
      url: '/comunicados',
      menus: []
    },
    {
      descricao: 'Canal BM',
      url: '/',
      menus: [
        {
          descricao: 'Tipos de Conteúdo',
          url: '/tipos-de-conteudo',
        }, {
          descricao: 'Categorias de Conteúdo',
          url: '/categorias-de-conteudo'
        }, {
          descricao: 'Tipos de Público Alvo',
          url: '/tipos-de-publico-alvo'
        }
      ]
    },
    {
      descricao: 'Cadastro de Conteúdo',
      url: '/conteudo-canal',
      menus: []
    },
    {
      descricao: 'Novidades',
      url: '/novidades',
      menus: []
    },
    {
      descricao: 'Permissão',
      url: '/permissao',
      menus: []
    }
  ];

  ngOnInit(): void {
  }

  mudaRota(rota) {
    this.router.navigate([rota.url]);
  }

  mudarPagina(pagina) {
    this.router.navigate([pagina]);
  }

  homeClick() {
    this.router.navigate(['/']);
  }
}
