import { DefaultModule } from './layouts/default/default.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pagina/home/home.component';
import { HeaderComponent } from './pagina/header/header.component';
import { BmInterceptorModule } from './interceptor/bm-interceptor.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './pagina/Utilitarios/material.module';
import { SlideComponent } from './pagina/slide/slide.component';
import { FormularioVisitanteComponent } from './pagina/formulario-visitante/formulario-visitante.component';
import { OrientacoesAudienciaComponent } from './pagina/orientacoes-audiencia/orientacoes-audiencia.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SlideComponent,
    FormularioVisitanteComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    BmInterceptorModule,
    DefaultModule
  ],
  exports: [],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
