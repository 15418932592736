<section class="galeria">
      <img src="/assets//image/layout.png" alt="" class="foto">
      <img src="/assets//image/layout2.png" alt="" class="foto">
      <div class="containerFormulario">
        <div class="formulario">
          <div class="titleForm">
            <h1>Fale com nossos especialistas</h1>
            <p>Informe seus dados para receber nosso contato</p>
          </div>
          <form [formGroup]="form" id="form_envio">
              <div class="input">
                <input type="text" nome="nome" formControlName="nome" placeholder="Nome*" title="Nome" >
              </div>
              <div class="input">
                <input type="text" nome="telefone" formControlName="telefone" placeholder="Telefone*" title="telefone"  mask="(00) 00000 - 0000">
              </div>
              <div class="input">
                <input type="text" nome="email"formControlName="email" placeholder="E-mail" title="E-mail">
              </div>
              <div class="input">
                <input type="text" nome="empresa" formControlName="empresa" placeholder="Empresa" title="empresa">
              </div>
              <div class="containeTermo">
                <input tabindex="-1" type="checkbox" name="termo" formControlName="termo" id="texto" title="checkbox">
                <label for="texto">
                  A Bruzon Mussi Advocacia usará os dados fornecidos neste formulário de acordo com a sua Política de Privacidade. Sei que posso mudar de ideia e revogar esta decisão a qualquer momento enviando um e-mail para contato@bruzonmussi.com.br
                </label>
              </div>
              <button type="submit" (click)="enviar()" [disabled]="load">
                 <span *ngIf="!load">Enviar</span>
                <div class="load" *ngIf="load"></div>
              </button>
          </form>
        </div>
      </div>
</section>
