import { __values } from 'tslib';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Component, OnInit, ViewChild, TemplateRef, AfterViewInit } from '@angular/core';
import { FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { BmServiceRequest } from '../Utilitarios/bm-service-request';
import { FormularioVisitanteComponent } from '../formulario-visitante/formulario-visitante.component';
import { HomeService } from './home.service';



export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private bmServiceRequest: BmServiceRequest,
    private homeService: HomeService,
  ) {
    if(window.innerWidth < 500){
      this.modoMobile = true;
    }
    this.buscaConteudo({ page: 0, 'page-size': this.modoMobile ? 1:3});
  }

  @ViewChild('modalformulario') modalformulario: TemplateRef<any>;

  oinitCont = 0;
  cookies = 'true';
  listaConteudo = [];
  spinnerSemConteudo = false;
  spinnerVisivel = false;
  modoMobile = false;
  tamanhoDaPagina = 0;
  totalPages:any;
  matcher:any;
  /*  nome = '';
  idConteudoCanal = '';
   telefone = '';
   empresa = '';
   cargo = '';
   mensagem = '';
   ip = ''; */



  ngOnInit(): void {
    this.cookies = localStorage.getItem("msgcookies")
    this.matcher = new MyErrorStateMatcher();


    
  }



  abrirContato() {
    window.open(`https://wa.me/55${'43999530385'}`, '_blank');//contato.contato.replace(/-/gm, '')
  }

  topoRecuperacao() {
    if(this.modoMobile){
      window.scrollTo(800, 100);
    }else{
      window.scrollTo(800, 0);
    }
    this.router.navigate(["/contratoErecuperacao"])
  }

  topDigital() {
    if(this.modoMobile){
      window.scrollTo(800, 100);
    }else{
      window.scrollTo(800, 0);
    }
    this.router.navigate(["/direitoDigital"])
  }

  topCivel() {
    if(this.modoMobile){
      window.scrollTo(800, 100);
    }else{
      window.scrollTo(800, 0);
    }
    this.router.navigate(["/civelContratos"])
  }

  clicKookies() {
    localStorage.setItem("msgcookies", "false");
    this.cookies = localStorage.getItem("msgcookies")
  }

  buscaConteudo(parametro) {
    this.spinnerVisivel = true;
    this.homeService.pesquisar('conteudoCanal/semConteudoBase64', parametro).subscribe((resposta) => {
    this.listaConteudo = [];
      this.totalPages = resposta.totalPages;
      resposta.content.map((conteudo) => {
        this.listaConteudo.push({
          id: conteudo.id,
          image: conteudo.miniaturaBase64,
          data: conteudo.cadastradoEm.substr(0,16),
          titulo: conteudo.titulo,
          conteudo: this.removerTags(conteudo.descricaoResumida),
          tipoConteudo: conteudo.tipoConteudo
        },
        );
      });
      this.spinnerVisivel = false;
    }, (erro) => {
      this.spinnerVisivel = false;
    });

  }

  formularioConteudo() {
    if(window.innerWidth < 500){
      window.scrollTo(800, 100);
    } else{
      window.scrollTo(800, 0);
    }
    this.router.navigate(["/conteudo/000"])
  }


  pushConteudo(idConteudo) {
    window.scroll(800, 300);
    this.router.navigate(['conteudo', 'home*'+idConteudo])
  }

  editorConfigLeitura: AngularEditorConfig = {
    editable: false,
    spellcheck: true,
    translate: 'no',
    enableToolbar: false,
    showToolbar: false,
    placeholder: 'Descrição: ',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '1',
    fonts: [
      { class: 'times-new-roman', name: 'Times New Roman', }
    ],
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'subscript',
        'superscript',
        'heading',
        'unlink',
        'link',
        'indent',
        'outdent',
        'strikeThrough',
        'fontName'
      ], [
        'insertImage',
        'insertVideo',
        'customClasses',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
  };

  formulario(idConteudo) {
    window.scrollTo(800, 300)
    if (sessionStorage.getItem("formulario") == null) {
      const dialogRef = this.dialog.open(FormularioVisitanteComponent, {
        data: { idConteudo: idConteudo },
        width: '',
        maxWidth: '80vw',
        minWidth: '',
        maxHeight: '80vh',
        disableClose: true,

      });
      dialogRef.beforeClosed().subscribe((fechou) => {
        if (fechou) {
          this.pushConteudo(idConteudo);
        }
      });
    } else {
      this.pushConteudo(idConteudo);
    }
  }

  reset(){
    document.querySelectorAll("input")
  }



  buscarConteudoMobile(valor){

    if (valor === '<' && this.tamanhoDaPagina > 0) {
      this.tamanhoDaPagina--;
    }
    if (valor === '>' ) {
      this.tamanhoDaPagina++;
    }
    this.buscaConteudo({ page: this.tamanhoDaPagina, 'page-size': 1 })

  }


  removerTags(html){
    const data = new DOMParser().parseFromString(html, 'text/html');
    return data.body.textContent.slice(0, 154)|| "";
 }
}





