import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BmInterceptorService } from './bm-interceptor.service';
import { NgModule } from '@angular/core';

@NgModule({
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BmInterceptorService,
            multi: true,
        },
    ],
})
export class BmInterceptorModule {}
