import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

declare let Toasty: any;

@Injectable({
  providedIn: 'root'
})
export class BmErros {
  private instance = null;
  constructor(public http: HttpClient, private _snackBar: MatSnackBar) {
    this.instance = new Toasty({
      insertBefore: true,
      transition: 'slideLeftFade',
      duration: 5000,
      prependTo: document.body.childNodes[0],
      enableSounds: true,
      sounds: {
        // path to sound for informational message:
        info: './assets/sounds/info/1.mp3',
        // path to sound for successfull message:
        success: './assets/sounds/success/1.mp3',
        // path to sound for warn message:
        warning: './assets/sounds/warning/1.mp3',
        // path to sound for error message:
        error: './assets/sounds/error/1.mp3',
      },
    });
  }

  showErro(mensagem) {

    if (mensagem !== undefined) {
      this.instance.error(mensagem);
    } else {
      this.instance.error('Erro ao realizar esta ação, Favor tente mais tarde');
    }
  }

  Informativo(mensagem) {

    if (mensagem !== undefined) {
      this.instance.info(mensagem);
    } else {
      this.instance.error('Erro ao realizar esta ação, Favor tente mais tarde');
    }

  }

  erroConvidado(mensagem) {
    this._snackBar.open(mensagem, 'OK!', {
      duration: 5000
    })
  }

  confirmacao(mensagem) {

    if (mensagem !== undefined) {
      this.instance.success(mensagem);
    } else {
      this.instance.error('Erro ao realizar esta ação, Favor tente mais tarde');
    }

  }
}
