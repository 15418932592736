import { catchError } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { BmErros } from '../pagina/Utilitarios/bm-erros';

@Injectable()
export class BmInterceptorService implements HttpInterceptor {
  constructor(private router: Router, private bmErros: BmErros) { }
  respostaInterceptor: any;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {


      if (localStorage.getItem('token')) {
        req = req.clone({
          headers: req.headers.set('Authorization', 'Bearer ' + localStorage.getItem('token')),
        });
      }

      return next.handle(req).pipe(catchError((Resposta: HttpEvent<any>) => {

        if (Resposta instanceof HttpErrorResponse) {
          if (Resposta.status === 403 && Resposta.error.message === 'Token expirado' || Resposta.message === 'Unauthorized') {
            localStorage.clear();
            this.router.navigate(['/login']);
            this.bmErros.Informativo('Sessão expirada! \n efetue login novamente');
          } else {
            return throwError(Resposta);
          }
          this.respostaInterceptor = Resposta.error.message;
        }
      }));
    }
  }




/*
if (screen.width < 1024 || screen.height < 768) {
  this.bmErros.Informativo('Tablet / celulares');

} else {
  // sirva a versão normal
  this.bmErros.Informativo('Computador');

} */
