import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment.prod';
import { BmErros } from './bm-erros';

@Injectable({
  providedIn: 'root'
})
export class BmServiceRequest {

  constructor(
    public http: HttpClient,
    private snackBar: MatSnackBar,
    public bmErros: BmErros) { }


  pesquisar(servico: string, parametros: any): Observable<any> {
    const params = Object.assign({}, parametros);
    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const element = params[key];
        if (element === null) {
          params.nome = ""
          params[key] = "";
        }
      }
    }
    return this.http
      .get(environment.BASE_URL + servico, {
        params: Object.assign(params),
      })
      .pipe(
        map((resposta: any) => {
          return resposta;
        }),
        catchError((resposta: HttpErrorResponse) => {
          this.bmErros.showErro(resposta.error.message);
          return throwError(resposta);
        })
      );
  }

  salvar(dadosDoFormulario: any, servico: string): Observable<any> {
    return this.http
      .post(
        environment.BASE_URL + servico,
        Object.assign(dadosDoFormulario)
      )
      .pipe(
        map((resposta: any) => {
            return resposta;
        }),
        catchError((resposta: HttpErrorResponse) => {
          this.bmErros.showErro(resposta.error.message);
          resposta.error.exception.details.map(detalhes => {
            this.bmErros.Informativo(detalhes.message)
          })
          return throwError(resposta);
        })
      );
  }



  salvarSemMsgAviso(dadosDoFormulario: any, servico: string): Observable<any> {
    try {
      return this.http
        .post(
          environment.BASE_URL + servico,
          Object.assign(dadosDoFormulario)
        )
        .pipe(
          map((resposta: any) => {
            if (!resposta.success) {
              return true;
            } else {
              return false;
            }
          }),
          catchError((resposta: HttpErrorResponse) => {
            resposta.error.exception.details.map(detalhes => {
            })
            return throwError(resposta);
          })
        );
    } catch (error) {
    }
  }
  buscaIpUsuario(): Observable<any> {
    return this.http.get('https://api.ipify.org').pipe(
      map((resposta: any) => {
        return resposta;
      }),
      catchError((resposta: HttpErrorResponse) => {
        return throwError(resposta);
      })
    );
  }

}
