<section class="container">
      <div  class="btnHamburguer">
        <button mat-button [matMenuTriggerFor]="menu"><mat-icon  ><i class="fas fa-bars"></i></mat-icon></button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="topHome()"><a>Inicio</a></button>
          <button mat-menu-item><a (click)="topSobreNos()">Sobre nós</a></button>
          <button mat-menu-item><a href="#areaAtuaçãoMobile" (click)="topAreaDeAtuacao()">Área de atuação</a></button>
          <button mat-menu-item><a  (click)="topConteudo()">Conteúdo</a></button>
          <button mat-menu-item ><a (click)="topTrabalheConosco()">Trabalhe conosco</a></button>
          <button mat-menu-item><a href="https://bmapps.bruzonmussi.com.br/app/portalcliente" target="_blank">Portal do cliente</a></button>
        </mat-menu>
      </div>
      <a href="#"><img class="imgBm" src="/assets/image/logo.jpg" alt=""></a>
      <div class="containerMenu">
        <ng-container >
          <button class="inicio"  (click)="topHome()"><a>Início</a>
            <hr size="3px" width="5px" color="#000" align="left">
          </button>
          <button class="menu3"><a (click)="topSobreNos()">Sobre nós</a>
          </button>
          <button class="menu"><a href="#areaAtuacao" (click)="topAreaDeAtuacao()">Áreas de atuação</a>
          </button>
          <button class="menu3"><a href="#conteudo" (click)=" topConteudo()">Conteúdos</a>
          </button>
          <button class="menu"><a (click)="topTrabalheConosco()">Trabalhe conosco</a>
          </button>
          <button class="menu"><a href="https://bmapps.bruzonmussi.com.br/app/portalcliente" target="_blank">Portal do cliente</a>
          </button>
        </ng-container>

      </div>
      <div class="socialMidia">
        <a [href]="whatsApp" target="_blank" (click)="abrirContato()"><i id="ico2" class="fab fa-whatsapp"></i></a>
        <a [href]="facebook" target="_blanck" (click)="abrirFacebook()"><svg  id="ico" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg></a>
        <a href="https://www.instagram.com/bruzonmussi.advocacia/" target="_blanck"><svg id="ico" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-instagram"><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg></a>
        <a href="https://www.linkedin.com/in/bruzon-mussi-2a66051a0/" target="_blanck"><svg id="ico" xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-linkedin"><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></svg></a>
      </div>
</section>
<router-outlet></router-outlet>

