 <!-- Aqui vai ficar localizado a apresentação  do site-->
 <section class="containerApresentacao ">
  <div class="containerLogo">
          <div><img class="logo" width="260px"src="../../../assets/image/Logomarca Bruzon Mussi Horizontal.png" alt="Logo do site"></div>
  </div>
  <div class="containerFaleConoso">
      <div class="texto">
        <i id="icone" style="font-size: 20px;" class="fas fa-user"></i>
        <a href="https://bmapps.bruzonmussi.com.br/app/portalcliente" target="_blank">
        <p class="xxx">Olá, acesse o <br> <strong>portal do Cliente</strong></p>
        </a>
      </div>
      <hr>
      <div class="botao">
          <button class="consulta">CONSULTE UM<br> ADVOGADO</button>
      </div>
  </div>
</section>
