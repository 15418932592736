import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BmErros } from '../Utilitarios/bm-erros';
import { BmServiceRequest } from '../Utilitarios/bm-service-request';

@Component({
  selector: 'bm-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    public bmerros:BmErros,
    public bmServiceRequest:BmServiceRequest,
  ) {

   }

   listaTipoConteudo = [];


  ngOnInit(): void {
    this.tipoConteudoCanal();
  }

  tipoConteudoCanal() {
    this.bmServiceRequest.pesquisar('tipoConteudoCanal', null).subscribe((resposta) => {
      resposta.map((tipoCategoria) => {
        this.listaTipoConteudo.push({
          id: tipoCategoria.id,
          descricao: tipoCategoria.descricao,
        },
        );
      });
    }, (erro) => {
    });
  }

  routerParaConteudo(id){
    this.router.navigate(['conteudo/','footer*'+id])
  }

  abrirContato() {
    991385573
    window.open(`https://wa.me/55${'43991385573'}`, '_blank');//contato.contato.replace(/-/gm, '')
  }

  abrirContatoEmail(){
    window.open(`mailto:${'brozunmussi@bruzonmussi.com.br'}`, '_blank');
  }


  copiaTexto(){
    let copia = document.getElementById("copiaEmail")
  }



  public copyForClipboard(event: MouseEvent): void {
    event.preventDefault();
    const payload: string = "contato@bruzonmussi.com.br";

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData || window["clipboardData"];
      clipboard.setData("text", payload.toString());
      e.preventDefault();
    };

    document.addEventListener("copy", listener, false)
    document.execCommand("copy");
    document.removeEventListener("copy", listener, false);
    this.bmerros.Informativo("Email copiado")
  }


  topoRecuperacao(){

    if(window.innerWidth < 500){
      window.scrollTo(800,100);
    } else{
      window.scrollTo(800,300);

    }
    this.router.navigate(["/contratoErecuperacao"])
  }

  topDigital(){
    if(window.innerWidth < 500){
      window.scrollTo(800,100)
    }else{
      window.scrollTo(800,300)
    }
    this.router.navigate(["/direitoDigital"])
  }

  topCivel(){
    if(window.innerWidth < 500){
      window.scrollTo(800,100)
    }else{
      window.scrollTo(800,300)
    }
    this.router.navigate(["/civelContratos"])
  }


  topPolitica(){
    if(window.innerWidth < 500){
      window.scrollTo(800,100)
    }else{
      window.scrollTo(800,0)
    }
    this.router.navigate(["/politicaDePrivacidade"])
  }

  topTrabalheConosco(){
    if(window.innerWidth < 500){
    window.scrollTo(800,100)
    }else{
    window.scrollTo(800,300)
    }
    this.router.navigate(["/trabalheConosco"])
  }

  topOrientacao(){
    if(window.innerWidth < 500){
    window.scrollTo(800,100)
    }else{
    window.scrollTo(800,300)
    }
    this.router.navigate(["/orientacoes_audiencias"])
  }


  top(){
    if(window.innerWidth < 500){
    window.scrollTo(800,100)
    }else{
    window.scrollTo(800,300)
    }
  }


}
