import { DefaultComponent } from './layouts/default/default.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pagina/home/home.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [{
  path: '',
  component: DefaultComponent,
  canActivate: [AuthGuard],
  canLoad: [AuthGuard],
  children: [{
      path: '',
      component: HomeComponent,
    },
    {
      path: 'conteudo/:id',
      loadChildren: () => import('./pagina/conteudo/conteudo.module').then(m => m.ConteudoModule),
    },
    {
      path: 'trabalheConosco',
      loadChildren: () => import('./pagina/trabalhe-conosco/trabalhe-conosco.module').then(m => m.TrabalheConoscoModule),
    },
    {
    path: 'contratoErecuperacao',
      loadChildren: () => import('./pagina/contrat-erecuperacao/contrat-erecuperacao.module').then(m => m.ContratErecuperacaoModule),
    },
    {
    path: 'orientacoes_audiencias',
      loadChildren: () => import('./pagina/orientacoes-audiencia/orientacoes-audiencia.module').then(m => m.OrientacoesAudienciaModule),
    },

    /* {
      path: 'direitoDigital',
        loadChildren: () => import('./pagina/direito-digital/direito-digial.module').then(m => m.DireitoDigialModule)
    }, */

    {
      path: 'politicaDePrivacidade',
        loadChildren: () => import('./pagina/politica/politica.module').then(m => m.PoliticaModule)
    },
    {
      path: 'civelContratos',
        loadChildren: () => import('./pagina/civel-contratos/civel-contratos.module').then(m => m.CivelContratosModule )
    },
    {
      path: 'visualizadorPdf/:base64',
      loadChildren: () => import('./pagina/visualizador-pdf/visualizador-pdf.module').then(m => m.VisualizadorPdfModule)
    }

  ]}];

  @NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
  })
export class AppRoutingModule { }
