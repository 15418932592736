import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BmServiceRequest } from '../Utilitarios/bm-service-request';
import { FormControl, Validators, FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { BmErros } from '../Utilitarios/bm-erros';

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}
@Component({
  selector: 'app-formulario-visitante',
  templateUrl: './formulario-visitante.component.html',
  styleUrls: ['./formulario-visitante.component.scss']
})
export class FormularioVisitanteComponent implements OnInit {
  emailFormControl = new FormControl('', [
    Validators.required,
    Validators.email,
  ]);

  controlFormError = new FormControl('', [
    Validators.required,
  ]);
  matcher = new MyErrorStateMatcher();
  campaignOne: FormGroup;
  campaignTwo: FormGroup;
  constructor(
    public bmErros: BmErros,
    public dialogRef: MatDialogRef<FormularioVisitanteComponent>,
    @Inject(MAT_DIALOG_DATA) public dataSource: any,
    private router: Router,
    public dialog: MatDialog,
    private bmServiceRequest: BmServiceRequest,) {
    const today = new Date();
    const month = today.getMonth();
    const year = today.getFullYear();
    this.campaignOne = new FormGroup({
      start: new FormControl(new Date(year, month, 13)),
      end: new FormControl(new Date(year, month, 16))
    });
    this.idConteudoCanal = dataSource.idConteudo;


  }

  ip = ''
  idConteudoCanal: any;
  nome = '';
  telefone = '';
  empresa = '';
  cargo = '';
  mensagem = '';
  teste = false;
  email = '';
  statusBotao = false;


  ngOnInit(): void {
    this.buscaIpUsuario();
  }


  buscaDadosUsuario(email) {
    if (email.includes('@')) {
      this.bmServiceRequest.pesquisar('siteVisitante', { email: email }).subscribe((resposta) => {
          this.email = resposta.content[0].email,
          this.nome = resposta.content[0].nome,
          this.empresa = resposta.content[0].empresa,
          this.telefone = resposta.content[0].telefone,
          this.cargo = resposta.content[0].cargo
          //this.mensagem = resposta.content[0].mensagem
      });
    }
  }


  salvar(form, email, formulario) {
   this.statusBotao = true;
    if(formulario.valid && email.length > 0){
      form = Object.assign(form, { email: email, ipVisitante: this.ip, idConteudoCanal: this.idConteudoCanal });
    this.bmServiceRequest.salvar(form, 'siteVisita').subscribe((resposta) => {
      sessionStorage.setItem("formulario", JSON.stringify(form));
      this.statusBotao = false;
      this.dialogRef.close(true);

    });
    }else{
      this.statusBotao = false;
      this.bmErros.showErro("Formulario inválido, verifique.")

      document.getElementById("nome").focus()
      document.getElementById("telefone").focus()
      document.getElementById("empresa").focus()
      document.getElementById("cargo").focus()
      document.getElementById("menssagem").focus()
      document.getElementById("enviar").focus()
    }
  }

/*   buscaIpUsuario() {
    this.bmServiceRequest.buscaIpUsuario().subscribe((retorno) => {
      if (retorno) {
        this.ip = retorno;
      }
    });
  }
 */
  buscaIpUsuario() {
    this.bmServiceRequest.salvar({ipVisitante:""} ,'buscaIp').subscribe((resposta) => {
      this.ip = resposta.ipVisitante;
    });
  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
